$(document).ready(function(){
	/* ---скрипт плавной прокрутки к якорю--- */  
  	$(".anchor").on("click", function(e){
		var anchor = $(this);
		$('html, body').stop().animate({
			scrollTop: $(anchor.attr('href')).offset().top
		}, 777);
		e.preventDefault();
		return false;
		});
	/*------------------Попапы--------------------*/
	$('.fancybox').fancybox({padding:0});
	/*--------------------------------------------*/
	/*----------------Мобильное меню-------------*/
	$('.menu-toggle').click(function(){
		$('.overlay').toggleClass('overlay-active');
		$(".mobile-menu").toggleClass('mobile-menu-active');
	});
	$('.mobile-menu__close').click(function(){
		$('.overlay').removeClass('overlay-active');
		$(".mobile-menu").removeClass('mobile-menu-active');
	});
	$('.overlay').click(function(){
		$('.overlay').removeClass('overlay-active');
		$(".mobile-menu").removeClass('mobile-menu-active');
	});
	$('.mobile-menu__item a').click(function(){
		$('.overlay').removeClass('overlay-active');
		$(".mobile-menu").removeClass('mobile-menu-active');
	});
	/*------------------------------------------------*/
	/*----------------Слайдер на главной-------------*/
	// $('.main-slider').owlCarousel({
	// 	items: 1,
	// 	dots: false,
	// 	nav: false,
	// 	loop: true,
	// 	autoplay: false,
	// 	autoplayTimeout:6000,
	// 	autoplaySpeed: 1500,
	// 	animateOut: 'fadeOut'
	// });
	mobileSlider();
	// $(window).resize(function() {
	// 	mobileSlider();
	// });
	function mobileSlider () {
		if (window.innerWidth < 768) {
			$('.mobile-slider').addClass('owl-carousel').removeClass('row');
			$('.mobile-slider').owlCarousel({
				items: 1,
				dots: true,
				nav: false,
				loop: true,
			});
			$('.services .mobile-slider').trigger('remove.owl.carousel', [9]);
			$('.services .mobile-slider').trigger('remove.owl.carousel', [4]);
		} else {
			$('.mobile-slider').removeClass('owl-carousel').addClass('row');
			$('.mobile-slider').trigger('destroy.owl.carousel');
		}
	}
	/*-----------------------------------------------*/
	/*--------Развертка каталога на главной----------*/ 
	$('.show-toggler button').click(function() {
		$(this).parent().nextAll('.hide-toggle').show(400);
		$(this).parent().hide();
		$(this).parent().nextAll('.hide-toggler').show();
	});
	$('.hide-toggler button').click(function() {
		$(this).parent().prevAll('.hide-toggle').hide(400);
		$(this).parent().hide();
		$(this).parent().prevAll('.show-toggler').show();
	});
	$('.about-toggle').click(function() {
		$('.about-toggle').addClass('d-none').removeClass('d-inline-block');
		$('.about .d-sm-block').removeClass('d-none');
	});
	/*------------------------------------------------*/
		/*----------------Формы----------------------*/
		$(document).on('submit', '.form-ajax', function(){
				var form = $(this);
				var action = form.attr('action');
				var formId = form.attr('id');
				var formData = new FormData(form.get(0));
				$.ajax({
					url: action,
					type: 'post',
					data: formData,
					contentType: false,
					processData: false,
					success: function(data){
						$('.form-alert').removeClass('d-none');
					}
				});
				return false;
			});
	/*------------------------------------------*/ 
});